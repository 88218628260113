<template>
 <div class="flex min-h-full">
  <!-- Static sidebar for desktop -->

  <main class="flex-1">
   <div class="py-8 xl:py-10">
    <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 xl:grid xl:max-w-full xl:grid-cols-3">
     <div class="xl:col-span-2 xl:border-r xl:border-gray-200 xl:pr-8">
      <div>
       <div>
        <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
         <div>
          <h1 class="text-2xl font-bold text-gray-900">
           {{ ticket.subject }}
          </h1>
          <p class="mt-2 text-sm text-gray-500">
           #{{ ticket.id }}
           <span v-if="ticket?.opener"> {{ $t("openedBy") }} </span>

           {{ " " }}
           <!-- <a href="#" class="font-medium text-gray-900">Hilary Mahy</a> -->
           <span v-if="ticket?.opener" class="font-medium text-gray-900">
            {{ ticket?.opener?.name }}
           </span>
           <!--   {{ " " }}
           in
           {{ " " }}
           <a href="#" class="font-medium text-gray-900">Customer Portal</a> -->

           <span
            v-show="ticket.open"
            :class="{
             encom_ouvert_ticket_new: ticket.status_id == 1,
             encom_en_traitement_ticket_new: ticket.status_id == 2,
             encom_en_attente_new: ticket.status_id == 3,
             encom_autre_ticket_new: ticket.status_id == 4,
            }"
            class="inline-flex items-center rounded-md ml-2 px-2 py-1 text-xs font-medium"
           >
            {{ checkStatus(ticket.status_id) }}
           </span>
          </p>
         </div>
        </div>
        <aside class="mt-8 pb-8 xl:hidden border-b border-gray-200">
         <h2 class="sr-only">Details</h2>
         <div class="space-y-5">
          <div class="flex items-center space-x-2">
           <font-awesome-icon
            :icon="ticket.open === 1 ? 'lock-open' : 'lock'"
            :class="[ticket.open === 1 ? 'text-green-500 ' : 'text-red-500', 'self-center h-4 w-4']"
           />
           <span
            :class="[ticket.open === 1 ? 'text-green-700 ' : 'text-red-700', 'text-sm font-medium']"
           >
            {{ ticket.open ? $t("ticketOpen") : $t("ticketClosed") }}
           </span>
          </div>
          <div class="flex items-center space-x-2">
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="h-5 w-5 text-gray-400"
           >
            <path
             fill-rule="evenodd"
             d="M10 3c-4.31 0-8 3.033-8 7 0 2.024.978 3.825 2.499 5.085a3.478 3.478 0 01-.522 1.756.75.75 0 00.584 1.143 5.976 5.976 0 003.936-1.108c.487.082.99.124 1.503.124 4.31 0 8-3.033 8-7s-3.69-7-8-7zm0 8a1 1 0 100-2 1 1 0 000 2zm-2-1a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z"
             clip-rule="evenodd"
            />
           </svg>

           <span class="text-sm font-medium text-gray-900">
            {{ $t("ticketReplies", { numb: ticketReplies.length }) }}
           </span>
          </div>
          <div v-if="ticket.created_at" class="flex items-center space-x-2">
           <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
           <span class="text-sm font-medium text-gray-900">
            {{ $t("createdAt") }}
            <time :datetime="ticket.created_at" class="capitalize">
             {{ $d(ticket.created_at, "longNumb") }}
            </time>
           </span>
          </div>
          <div v-if="ticket.updated_at" class="flex items-center space-x-2">
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="h-5 w-5 text-gray-400"
           >
            <path
             d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z"
            />
           </svg>

           <span class="text-sm font-medium text-gray-900">
            {{ $t("lastModification") }}
            <time :datetime="ticket.updated_at" class="capitalize">
             {{ $d(ticket.updated_at, "longNumb") }}
            </time>
           </span>
          </div>
         </div>

         <!--    <div class="mt-6 space-y-8 border-b border-t border-gray-200 py-6">
          <div>
           <h2 class="text-sm font-medium text-gray-500">Assignees</h2>
           <ul role="list" class="mt-3 space-y-3">
            <li class="flex justify-start">
             <a href="#" class="flex items-center space-x-3">
              <div class="flex-shrink-0">
               <img
                class="h-5 w-5 rounded-full"
                src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                alt=""
               />
              </div>
              <div class="text-sm font-medium text-gray-900">Eduardo Benz</div>
             </a>
            </li>
           </ul>
          </div>
          <div>
           <h2 class="text-sm font-medium text-gray-500">Tags</h2>
           <ul role="list" class="mt-2 leading-8">
            <li class="inline">
             <a
              href="#"
              class="relative inline-flex items-center rounded-full px-2.5 py-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
             >
              <div class="absolute flex flex-shrink-0 items-center justify-center">
               <span class="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
              </div>
              <div class="ml-3 text-xs font-semibold text-gray-900">Bug</div>
             </a>
             {{ " " }}
            </li>
            <li class="inline">
             <a
              href="#"
              class="relative inline-flex items-center rounded-full px-2.5 py-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
             >
              <div class="absolute flex flex-shrink-0 items-center justify-center">
               <span class="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true" />
              </div>
              <div class="ml-3 text-xs font-semibold text-gray-900">Accessibility</div>
             </a>
             {{ " " }}
            </li>
           </ul>
          </div>
         </div> -->
        </aside>
        <div class="py-3 xl:pb-0 xl:pt-6">
         <h2 class="sr-only">Description</h2>
         <div class="prose max-w-none">
          <p v-html="ticket.body" class="ticketBody"></p>
         </div>
         <div
          v-if="ticket.attachments && ticket.attachments.length > 0"
          class="mt-5 overflow-hidden"
         >
          <div class="px-3 py-5 border-t border-gray-200 sm:px-6">
           <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-2">
             <dt class="text-sm font-medium text-gray-500">{{ $t("attachments") }}</dt>
             <dd class="mt-1 text-sm text-gray-900">
              <ul
               role="list"
               class="bg-white border border-gray-200 divide-y divide-gray-200 rounded-md"
              >
               <li
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                v-for="attachment in ticket.attachments"
                :key="attachment.id"
               >
                <div class="flex items-center flex-1 w-0">
                 <PaperClipIcon class="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                 <span class="flex-1 w-0 ml-2 truncate"> {{ attachment.filename }} </span>
                </div>
                <div class="flex-shrink-0 ml-4">
                 <button
                  @click="openFile(attachment.id, attachment.mimeType)"
                  class="font-medium encom_primary_text_dark encom_primary_text_dark_hover"
                 >
                  {{ $t("download") }}
                 </button>
                </div>
               </li>
              </ul>
             </dd>
            </div>
           </dl>
          </div>
         </div>
        </div>
       </div>
      </div>
      <section aria-labelledby="activity-title" class="mt-8 xl:mt-10">
       <div>
        <div class="divide-y divide-gray-200">
         <div class="pb-4">
          <h2 id="activity-title" class="text-lg font-medium text-gray-900">
           {{ $t("activityFeed") }}
          </h2>
         </div>
         <div class="pt-6">
          <!-- Activity feed-->
          <div v-if="ticketReplies.length > 0" class="flow-root">
           <div v-for="reply in ticketReplies" :key="reply.id">
            <div class="grid grid-col-10 px-2 py-2 flex justify-between">
             <div class="bubbleWrapper xl:pl-10">
              <div
               v-if="reply.origin === 'admin'"
               :class="[
                reply.customerVisible === 0 ? '' : '',
                ' inlineContainer other w-60 lg:w-auto lg:max-w-2xl ',
               ]"
              >
               <div>
                <div class="other flex justify-between mt-2 mx-5" v-if="reply.origin === 'admin'">
                 <p class="text-gray-500 text-xs text-left mr-2 capitalize">
                  {{ $d(reply.created_at, "longText") }}
                 </p>
                 <p class="text-gray-500 text-xs text-left">
                  {{ reply.id ? getUserReply(reply.id) : "" }}
                 </p>
                </div>
                <div
                 :class="[
                  reply.customerVisible === 0 ? 'internalNote' : 'otherBubble',
                  'other py-5 sm:p-6 text-sm break-words',
                 ]"
                >
                 <p>
                  <span v-html="reply.body"></span>
                 </p>

                 <div
                  v-if="reply.attachments && reply.attachments.length > 0"
                  class="hidden sm:block"
                  aria-hidden="true"
                 >
                  <div class="py-2">
                   <div class="border-t border-gray-200" />
                  </div>
                 </div>
                 <div
                  v-if="reply.attachments && reply.attachments.length > 0"
                  class="px-2 py-1 text-xs"
                 >
                  <ul>
                   <li v-for="document in reply.attachments" :key="document.id">
                    <span
                     @click="openFile(document.id, document.mimeType)"
                     class="underline hover:text-gray-800 -ml-2 cursor-pointer flex justify-start gap-1"
                    >
                     <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-5 h-5"
                     >
                      <path
                       fill-rule="evenodd"
                       d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                       clip-rule="evenodd"
                      />
                     </svg>

                     {{ document.filename }}
                     <div class="flex gap-1">
                      <Loader v-if="loadDocument" />
                     </div>
                    </span>
                   </li>
                  </ul>
                 </div>
                </div>
                <div class="flex justify-end mr-4 -mt-1" v-if="reply.customerVisible === 0">
                 <p class="text-gray-500 text-xs italic font-semibold">
                  {{ $t("internalReplyContent") }}
                 </p>
                </div>
               </div>
              </div>
             </div>
             <div class="bubbleWrapper col-end-10">
              <div
               v-if="reply.origin !== 'admin'"
               class="inlineContainer text-left w-60 lg:w-auto lg:max-w-2xl"
              >
               <div>
                <div class="own flex justify-between mt-2 mx-5">
                 <p class="text-gray-500 text-xs text-left mr-2 capitalize">
                  {{ $d(reply.created_at, "longText") }}
                 </p>
                 <p class="text-gray-500 text-xs text-left">
                  {{ reply.id ? getUserReply(reply.id) : "" }}
                 </p>
                </div>
                <div class="ownBubble own px-4 py-5 sm:p-6 text-sm break-words -mt-5">
                 <p>
                  <span v-html="reply.body"></span>
                 </p>
                 <div
                  v-if="reply.attachments && reply.attachments.length > 0"
                  class="hidden sm:block"
                  aria-hidden="true"
                 >
                  <div class="py-2">
                   <div class="border-t border-gray-200" />
                  </div>
                 </div>
                 <div
                  v-if="reply.attachments && reply.attachments.length > 0"
                  class="px-2 py-1 text-xs"
                 >
                  <ul>
                   <li v-for="document in reply.attachments" :key="document.id">
                    <span
                     @click="openFile(document.id, document.mimeType)"
                     class="underline hover:text-gray-800 cursor-pointer flex justify-end gap-1"
                    >
                     <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-5 h-5"
                     >
                      <path
                       fill-rule="evenodd"
                       d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                       clip-rule="evenodd"
                      />
                     </svg>

                     {{ document.filename }}
                     <div class="flex gap-1">
                      <Loader v-if="loadDocument" />
                     </div>
                    </span>
                   </li>
                  </ul>
                 </div>
                </div>
               </div>
              </div>
             </div>
            </div>
           </div>
          </div>
          <div v-else class="text-center">
           <font-awesome-icon icon="comment" class="h-10 w-10 text-gray-400" aria-hidden="true" />

           <p class="mt-1 text-sm text-gray-500">{{ $t("noMessage") }}.</p>
          </div>

          <div class="mt-6">
           <div class="flex space-x-3">
            <!--  <div class="flex-shrink-0">
             <div class="relative">
              <img
               class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
               src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
               alt=""
              />

              <span class="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
               <ChatBubbleLeftEllipsisIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
             </div>
            </div> -->
            <div class="min-w-0 flex-1">
             <form
              v-if="
               userPermissions.permissions.includes('ticketing.*') ||
               userPermissions.permissions.includes('ticketing.edit') ||
               (userPermissions.permissions.includes('ticketing.edit-own') && ticket.is_opener)
              "
              class="space-y-2"
              @submit.prevent="reply($t('ticketTable.successMsg'))"
              method="POST"
             >
              <div class="space-y-8">
               <div class="grid grid-cols-1 gap-2 sm:grid-cols-6">
                <div class="sm:col-span-8 rounded-xl">
                 <div class="relative" id="app">
                  <div class="absolute pt-2 pr-3 z-10 right-0">
                   <label
                    for="file-upload"
                    class="relative flex items-center gap-1 text-sm font-medium underline rounded-md cursor-pointer hover:text-gray-800"
                   >
                    <svg
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20"
                     fill="currentColor"
                     class="w-4 h-4"
                    >
                     <path
                      fill-rule="evenodd"
                      d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                      clip-rule="evenodd"
                     />
                    </svg>

                    <span> {{ $t("uploadAFile") }} </span>
                    <input
                     id="file-upload"
                     name="file-upload"
                     type="file"
                     ref="file"
                     class="sr-only"
                     accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     @change="addReplyDocument($event)"
                    />
                   </label>
                  </div>
                  <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                 </div>
                 <div
                  class="bg-white border-b border-l border-r border-gray-300"
                  v-if="replyDocuments.length > 0"
                 >
                  <ul role="list" class="divide-y divide-gray-300">
                   <li
                    v-for="(attachment, idx) in replyDocuments"
                    :key="attachment.id"
                    class="flex items-center justify-between py-1 pl-2 pr-4 text-sm"
                   >
                    <div class="flex items-center flex-1 w-0">
                     <PaperClipIcon
                      class="flex-shrink-0 w-5 h-5 text-gray-400 mr-1"
                      aria-hidden="true"
                     />
                     {{ attachment.name }}
                    </div>
                    <div class="flex items-center flex-shrink-0 gap-1 ml-4 text-sm">
                     <a
                      @click="replyDocuments.splice(idx, 1)"
                      class="font-medium text-red-600 cursor-pointer hover:text-red-500"
                      :title="this.$t('delete')"
                      ><svg
                       xmlns="http://www.w3.org/2000/svg"
                       class="w-4 h-4"
                       fill="none"
                       viewBox="0 0 24 24"
                       stroke="currentColor"
                      >
                       <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                       /></svg
                     ></a>
                    </div>
                   </li>
                  </ul>
                 </div>
                </div>
               </div>
              </div>
              <div class="mt-6 flex items-center justify-end space-x-4">
               <button
                type="button"
                class="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
               >
                <span v-if="ticket.open == 1" class="flex space-x-2">
                 <CheckCircleIcon class="-ml-1 mr-2 h-5 w-5 text-green-500" aria-hidden="true" />
                 {{ $t("closeTicket") }}
                </span>
                <span v-else>
                 {{ $t("reopenTicket") }}
                </span>
               </button>
               <button
                type="submit"
                :disabled="editorData.trim() === ''"
                :class="{
                 'body__table--header opacity-50 cursor-default': editorData.trim() === '',
                 'body__table--header': editorData.trim() !== '',
                 'rounded inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white': true,
                }"
                class="inline-flex items-center justify-center rounded-md body__table-pagination--button px-3 py-2 text-sm font-semibold text-white shadow-sm"
               >
                {{ $t("ticket.send") }}
               </button>
              </div>
             </form>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </section>
     </div>
     <aside class="hidden xl:block xl:pl-8">
      <h2 class="sr-only">Details</h2>
      <div class="space-y-5 border-b pb-6 border-gray-200">
       <div class="flex items-center space-x-2">
        <font-awesome-icon
         :icon="ticket.open === 1 ? 'lock-open' : 'lock'"
         :class="[ticket.open === 1 ? 'text-green-500 ' : 'text-red-500', 'self-center h-4 w-4']"
        />
        <span
         :class="[ticket.open === 1 ? 'text-green-700 ' : 'text-red-700', 'text-sm font-medium']"
        >
         {{ ticket.open ? $t("ticketOpen") : $t("ticketClosed") }}
        </span>
       </div>
       <div class="flex items-center space-x-2">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         class="h-5 w-5 text-gray-400"
        >
         <path
          fill-rule="evenodd"
          d="M10 3c-4.31 0-8 3.033-8 7 0 2.024.978 3.825 2.499 5.085a3.478 3.478 0 01-.522 1.756.75.75 0 00.584 1.143 5.976 5.976 0 003.936-1.108c.487.082.99.124 1.503.124 4.31 0 8-3.033 8-7s-3.69-7-8-7zm0 8a1 1 0 100-2 1 1 0 000 2zm-2-1a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z"
          clip-rule="evenodd"
         />
        </svg>

        <span class="text-sm font-medium text-gray-900">
         {{ $t("ticketReplies", { numb: ticketReplies.length }) }}
        </span>
       </div>
       <div v-if="ticket.created_at" class="flex items-center space-x-2">
        <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        <span class="text-sm font-medium text-gray-900">
         {{ $t("createdAt") }}
         <time :datetime="ticket.created_at" class="capitalize">
          {{ $d(ticket.created_at, "longNumb") }}
         </time>
        </span>
       </div>
       <div v-if="ticket.updated_at" class="flex items-center space-x-2">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         class="h-5 w-5 text-gray-400"
        >
         <path
          d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z"
         />
        </svg>

        <span class="text-sm font-medium text-gray-900">
         {{ $t("lastModification") }}
         <time :datetime="ticket.updated_at" class="capitalize">
          {{ $d(ticket.updated_at, "longNumb") }}
         </time>
        </span>
       </div>
      </div>
      <!--   <div class="mt-6 space-y-8 border-t border-gray-200 py-6">
       <div>
        <h2 class="text-sm font-medium text-gray-500">Assignees</h2>
        <ul role="list" class="mt-3 space-y-3">
         <li class="flex justify-start">
          <a href="#" class="flex items-center space-x-3">
           <div class="flex-shrink-0">
            <img
             class="h-5 w-5 rounded-full"
             src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
             alt=""
            />
           </div>
           <div class="text-sm font-medium text-gray-900">Eduardo Benz</div>
          </a>
         </li>
        </ul>
       </div>
       <div>
        <h2 class="text-sm font-medium text-gray-500">Tags</h2>
        <ul role="list" class="mt-2 leading-8">
         <li class="inline">
          <a
           href="#"
           class="relative inline-flex items-center rounded-full px-2.5 py-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
           <div class="absolute flex flex-shrink-0 items-center justify-center">
            <span class="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
           </div>
           <div class="ml-3 text-xs font-semibold text-gray-900">Bug</div>
          </a>
          {{ " " }}
         </li>
         <li class="inline">
          <a
           href="#"
           class="relative inline-flex items-center rounded-full px-2.5 py-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
           <div class="absolute flex flex-shrink-0 items-center justify-center">
            <span class="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true" />
           </div>
           <div class="ml-3 text-xs font-semibold text-gray-900">Accessibility</div>
          </a>
          {{ " " }}
         </li>
        </ul>
       </div>
      </div> -->
     </aside>
    </div>
   </div>
  </main>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import TicketListDetail from "../components/TicketListDetail.vue";
import SearchFilter from "../components/SearchFilter.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

import ImageInsertPlugin from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import AutoImagePlugin from "@ckeditor/ckeditor5-image/src/autoimage";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";

import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
import ListStylePlugin from "@ckeditor/ckeditor5-list/src/liststyle";

import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

import {
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Dialog,
 DialogPanel,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import {
 PaperClipIcon,
 FilterIcon,
 SearchIcon,
 BellIcon,
 CalendarIcon,
 ChatBubbleLeftEllipsisIcon,
 CheckCircleIcon,
 LockOpenIcon,
 MagnifyingGlassIcon,
 PencilIcon,
 TagIcon,
 UserCircleIcon as UserCircleIconMini,
} from "@heroicons/vue/solid";
import TicketsHeader from "./TicketsHeader.vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "TicketDetail",
 props: {
  ticketId: {
   type: String,
   required: true,
  },
 },
 components: {
  ComfirmationDialog,
  TicketListDetail,
  SearchFilter,
  Loading,
  FilterIcon,
  SearchIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  BellIcon,
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  LockOpenIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TagIcon,
  UserCircleIconMini,
  TicketsHeader,
  PaperClipIcon,
 },

 data() {
  return {
   account,
   isLoading: true,
   fullPage: false,
   editor: ClassicEditor,
   editorData: "",
   editorConfig: {
    fillEmptyBlocks: false,
    tabSpaces: 0,
    placeholder: "",
    plugins: [
     EssentialsPlugin,
     BoldPlugin,
     ItalicPlugin,
     LinkPlugin,
     ParagraphPlugin,
     ImageInsertPlugin,
     SimpleUploadAdapter,
     ImagePlugin,
     ImageResizePlugin,
     ImageStylePlugin,
     ImageToolbarPlugin,
     AutoImagePlugin,
     IndentPlugin,
     IndentBlockPlugin,
     ListStylePlugin,
    ],
    simpleUpload: {
     // The URL that the images are uploaded to.
     uploadUrl: `${this.$cookie.getCookie("API")}/api/v1/pictures`,

     // Enable the XMLHttpRequest.withCredentials property.
     withCredentials: false,

     // Headers sent along with the XMLHttpRequest to the upload server.
     headers: {
      "X-CSRF-TOKEN": "CSRF-Token",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    },
    toolbar: {
     items: [
      "heading",
      "bold",
      "italic",
      "|",
      "link",
      "|",
      "undo",
      "redo",
      "|",
      "indent",
      "outdent",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertImage",
     ],
    },
    indentBlock: {
     offset: 1,
     unit: "em",
    },
    language: "en",
    image: {
     toolbar: ["imageTextAlternative", "imageStyle:inline", "imageStyle:block", "imageStyle:side"],
    },
    licenseKey: "",
   },
   ticket: {},
   id: "",
   statusName: "",
   ticketUser: {},
   isFocus: false,
   showMessage: false,
   subMessageToShow: "",
   messageToShow: "",
   search: "",
   searchStatus: [],
   statusList: [],
   isListShow: true,
   userName: "",
   ticketReplies: [],
   count: 0,
   interval: undefined,
   userPermissions,
   replyDocuments: [],
  };
 },
 methods: {
  getUserReply(id) {
   let name = "";
   for (let index = 0; index < this.ticketReplies.length; index++) {
    if (this.ticketReplies[index].id == id) {
     name =
      this.ticketReplies[index].user?.name && this.ticketReplies[index].origin !== "admin"
       ? this.ticketReplies[index].user?.name
       : "";
     return name;
    }
   }
  },

  async getTicket(account) {
   this.$emit("setLoading", true);
   const id = this.$route.params.id;
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    if (res) {
     this.ticket = res.data;
     this.id = res.data.id;
     this.ticketReplies = res.data.replies;
     this.$emit("setLoading", false);
     this.$forceUpdate();
     if (res.data.assignee_id) {
      // this.getUser(res.data.assignee_id);
     }
    }
   } catch (error) {
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  async getTicketReplies(id) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    if (res) {
     this.ticketReplies = res.data.replies;
     this.isLoading = false;
     this.$forceUpdate();
    }
   } catch (error) {
    this.isLoading = false;
    this.errorHandling(error);
   }
  },
  async getStatus() {
   const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/statuses`, {
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   });
   this.statusList = res.data;
  },
  getTicketDetail(...args) {
   this.$router.push(`/ticket/${args[0]}`);
  },
  pushSearchStatus(status) {
   if (this.searchStatus.includes("closed")) {
    let index = this.searchStatus.indexOf("closed");
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
   if (!this.searchStatus.includes(status)) {
    this.searchStatus.push(status);
   } else {
    let index = this.searchStatus.indexOf(status);
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
  },
  showClosedTickets() {
   if (!this.searchStatus.includes("closed")) {
    this.searchStatus.length = 0;
    this.searchStatus.push("closed");
   } else {
    let index = this.searchStatus.indexOf("closed");
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
  },
  async reply(successMsg) {
   if (this.editorData === "") {
    //window.alert("Please write something before replying.");
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", "Please write something before replying.");
   }
   let str = String(this.editorData);
   // let noSpace = str.replaceAll("&nbsp;", "");
   let noSpace = str.split("&nbsp;").join("");
   if (localStorage.getItem("token"))
    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/replies?customerAccount=${this.account}&ticketId=${
       this.id
      }&body=${noSpace}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then((res) => {
      let id = res.data.id;
      for (let index = 0; index < this.replyDocuments.length; index++) {
       const element = this.replyDocuments[index];
       this.postReplyDocument(id, element);
      }
     })
     .finally(() => {
      window.scrollTo(0, document.body.scrollHeight);
      this.editorData = "";
      this.replyDocuments = [];
      this.getTicketReplies(this.id);
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", successMsg);
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
  },
  /*   async getUser(id) {
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/users/${id}`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    this.userName = res.data.name;
   } catch (error) {
    this.errorHandling(error);
   }
  }, */
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " at " +
    dateCreated.getHours() +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  addCount() {
   this.count = this.count + 1;
  },
  async openOrCloseTicket(open, id) {
   axios
    .put(
     `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}?customerAccount=${this.account}&open=${
      open == 0 ? 1 : 0
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    )
    .then((res) => {
     let successMsg =
      open == 0 ? this.$t("ticketTable.reopenMsg") : this.$t("ticketTable.closeMsg");
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", successMsg);
     open == 0 ? this.$router.push("/tickets") : this.$router.push("/closed-tickets");
    });
  },
  checkStatus(id) {
   if (this.statusList) {
    for (let index = 0; index < this.statusList.length; index++) {
     if (this.statusList[index].id == id) {
      return this.statusList[index].name;
     }
    }
   }
  },
  checkStatusColor(id) {
   if (this.statusList) {
    for (let index = 0; index < this.statusList.length; index++) {
     if (this.statusList[index].id == id) {
      return this.statusList[index].color;
     }
    }
   }
  },
  async getTickets() {
   if (localStorage.getItem("token")) {
    this.$emit("setLoading", true);
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie("API")}/api/v1/tickets?customerAccount=${account}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );

     this.$emit(
      "tickesCount",
      this.filterOpenTickets(res.data).length,
      this.filterClosedTickets(res.data).length
     );
    } catch (error) {
     this.errorHandling(error);
    }
    this.$emit("setLoading", false);
   }
  },
  filterOpenTickets(data) {
   return data.filter((res) => {
    return res.open;
   });
  },
  filterClosedTickets(data) {
   return data.filter((res) => {
    return !res.open;
   });
  },
  addReplyDocument() {
   this.replyDocuments.push(this.$refs.file.files[0]);
  },
  async openFile(id, mimeType) {
   let url = `${this.$cookie.getCookie("API")}/api/v1/attachments/${id}/content?customerAccount=${
    this.account
   }`;
   const res = await axios
    .get(url, {
     responseType: "arraybuffer",
    })
    .catch((err) => {
     console.error("Error while trying to download document: ", err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });

   const file = new Blob([await res.data], { type: mimeType });
   const fileURL = URL.createObjectURL(file);
   if (!res.data) {
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", "Document not found");
   } else {
    window.open(fileURL);
   }
  },

  postReplyDocument(id, file) {
   let url = `${this.$cookie.getCookie("API")}/api/v1/attachments?customerAccount=${this.account}`;
   let formData = new FormData();
   formData.append("file", file);
   formData.append("attachable_id", id);
   formData.append("attachable_type", "App\\\Reply");
   axios
    .post(url, formData)
    .then((res) => {
     this.getTicket(this.account);
    })
    .finally(() => {
     this.isLoading = false;
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
     this.isLoading = false;
    });
  },
 },
 mounted() {
  this.getStatus();
  this.getTickets();
  this.getTicket(this.account);
  if (this.$route.path === "/closed-tickets") {
   this.searchStatus.push("closed");
  }
 },

 watch: {
  $route(to, from) {
   // Call a method to reload the ticket data here
   if (this.account && this.$route.params.id) this.getTicket(this.account);
  },
 },
 created() {
  // this.interval = setInterval(() => this.getTicketReplies(this.id), 1000);
 },
 computed: {
  ...mapGetters(["token"]),
 },
 beforeUnmount() {
  clearInterval(this.interval);
 },
};
</script>

<style scoped>
.bubbleWrapper {
 padding: 10px 5px;
 display: flex;
 justify-content: flex-end;
 flex-direction: column;
 align-self: flex-end;
 color: #fff;
}
.inlineContainer {
 display: inline-flex;
}
.inlineContainer.own {
 flex-direction: row-reverse;
}
.inlineIcon {
 width: 20px;
 object-fit: contain;
}
.internalNote {
 min-width: 60px;
 max-width: 700px;
 padding: 14px 18px;
 margin: 6px 8px;
 background-color: #22384b;
 border-radius: 10px 10px 0 10px;
}
.ownBubble {
 min-width: 60px;
 max-width: 700px;
 padding: 7px 8px;
 margin: 3px 8px;
 background-color: #80cab0;
 border-radius: 10px 10px 0 10px;
}
.otherBubble {
 min-width: 60px;
 max-width: 700px;
 padding: 7px 8px;
 margin: 3px 8px;
 background-color: #6c8ea4;
 border-radius: 10px 10px 10px 0;
}
.own {
 align-self: flex-end;
}
.other {
 align-self: flex-start;
}
span.own,
span.other {
 font-size: 14px;
 color: grey;
}
.ticketBody :deep(img) {
 max-height: 500px;
 max-width: 80%;
}
</style>
